













































































































import { Component, Mixins } from 'vue-property-decorator'
import StepCounter from '@/components/step-counter/index.vue'
import HorakiEngine from '@/components/horaki-engine/index.vue'
import { binderModule, brokerageModule, cobModule } from '@/store/store-accessor'
import { validateForm } from '@/helpers/validations'
import { Question } from '@/models/questionnaire'
import TheSideNavigation from '@/components/the-side-navigation/index.vue'
import ApplicationMixin from '@/mixins/application-mixin'
import ApplicationLayout from '@/layouts/application-layout.vue'
import LoadingIcon from '@/components/loading-icon/index.vue'
import Spacer from '@/components/spacer/index.vue'
import LoadableMixin from '@/mixins/loadable-mixin'
import { Section } from '@/models/layout'
import { ROUTES } from '@/router/routes'
import SaveForLaterForm from '@/components/save-for-later/index.vue'
import Drawer from '@/components/drawer/index.vue'
import BrokerageMixin from '@/mixins/brokerage-mixin'
import DrawerSaveForLater from '@/components/save-for-later/drawer/index.vue'
import IconBase from '@/components/icon-library/icon-base.vue'
import Save from '@/components/icon-library/save.vue'
import DrawerBusinessInfo from '@/components/business-info/drawer/index.vue'
import { generateCompanyKey } from '@/helpers/utils'

@Component({
  name: 'Application',
  components: {
    DrawerSaveForLater,
    IconBase,
    ApplicationLayout,
    TheSideNavigation,
    StepCounter,
    HorakiEngine,
    LoadingIcon,
    Spacer,
    SaveForLaterForm,
    Drawer,
    Save,
    DrawerBusinessInfo
  }
})
export default class Application extends Mixins(ApplicationMixin, LoadableMixin, BrokerageMixin) {
  // Question list for the application
  get questions (): Question[] {
    if (!binderModule.application) {
      return [] // Still loading
    }
    return binderModule.application.questionnaire.questions
  }

  get layout (): Section[] {
    if (!binderModule.application) {
      return [] // Still loading
    }
    return binderModule.application.questionnaire.layout as Section[]
  }

  get showSaveForLater (): boolean {
    return binderModule.toggleSaveForLater
  }

  get isEditingQuestion (): string {
    return binderModule.toggleEditQuestion
  }

  get BusinessName (): string | null {
    return this.answers.BusinessName as string || null
  }

  toggleSaveForLater (): void {
    binderModule.setSaveForLater()
  }

  toggleEditQuestion (): void {
    binderModule.setEditQuestion('')
  }

  async created () {
    window.scrollTo(0, 0)
    this.showLoading()

    try {
      await Promise.all([
        binderModule.checkApplication(),
        cobModule.initializeCOB()
      ])
    } catch (err: any) {
      this.$logger.warn(`Unsuccesful load of application: ${err.message}`)
    }

    if (!binderModule.application) {
      return this.$router.push({
        name: ROUTES.PAGE_NOT_FOUND
      })
    }

    await binderModule.setQuestionnaire(binderModule.application.questionnaire)

    document.title = brokerageModule.brokerage
      ? `${brokerageModule.brokerage.name} | ${this.$route?.meta?.title}`
      : this.$route?.meta?.title

    this.setLocalStorageVariables()
    this.hideLoading()
  }

  setLocalStorageVariables () {
    const key = generateCompanyKey(this.answers)
    const token = sessionStorage.getItem('briza-application-token') || ''
    if (token) {
      sessionStorage.removeItem(`${key}_dialog`)
      localStorage.setItem(`${key}_token`, token)
      localStorage.setItem(`${key}_id`, binderModule.application?.id || '')
      localStorage.setItem(`${key}_route`, ROUTES.APPLICATION)
    }
  }

  // Continue button click to move to next section in the pre-app
  async moveNext () {
    this.showBtnLoading()

    const validationMessages = validateForm(
      this.$refs.application as HTMLFormElement,
      this.questions,
      this.answers
    )
    binderModule.updateFormValidation(validationMessages)

    if (validationMessages.length > 0) {
      this.hideBtnLoading()
      return this.focusField(validationMessages[0].field)
    }

    // only fire api request if validation passes
    await binderModule.saveAnswers()

    if (this.applicationComplete === false) {
      this.hideBtnLoading()
      return this.showNextSection()
    }

    return this.$router.push({
      name: ROUTES.QUOTES,
      params: { id: binderModule.application?.id as string }
    })
  }
}
