


























































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Section } from '@/models/layout'
import QuestionComponent from '@/components/question-component/index.vue'
import QuestionGroupLabel from '@/components/question-component/question-group-label.vue'
import { AnswerMap, AnswerValue, isLayoutSection, LayoutSection, Question, SectionItem } from '@/models/questionnaire'
import { renderMarkdown } from '@/helpers/markdown'
import { isGroupVisible } from '@/helpers/layout'
import { engine } from '@/engine'
import { binderModule } from '@/store/store-accessor'

@Component({
  name: 'HorakiEngine',
  components: {
    QuestionComponent, QuestionGroupLabel
  }
})
export default class HorakiEngine extends Vue {
  @Prop() layout!: Section[]
  @Prop() questions!: Question[]
  @Prop() visibleSections!: string[]
  @Prop() answers!: AnswerMap
  @Prop() depth!: number

  isSectionVisible (section: Section): boolean {
    return this.visibleSections.includes(section.sectionId) || this.shouldShowNextSection(section)
  }

  sectionsAreVisible (sections: Section[]): boolean {
    return sections.some(section => this.isSectionVisible(section))
  }

  numberOfLocations (): AnswerValue {
    if (!binderModule.answers) return 1 // default showing one location
    return binderModule.answers.NumberOfLocations
  }

  /**
   * Increment NumberOfLocations by 1
   */
  addLocation () {
    binderModule.incrementLocation()
  }

  /**
   * Remove the last location
   */
  removeLocation () {
    binderModule.decrementLocation(`Location${this.numberOfLocations}Address`)
  }

  shouldShowNextSection (section: Section | LayoutSection): boolean {
    return section.items.some(item => {
      if (isLayoutSection(item)) {
        return this.shouldShowNextSection(item)
      }

      const question = this.questions.find(question => question.id === item)
      return question?.condition && engine.evaluate(question.condition, this.answers)
    })
  }

  /**
   * A group is visible if all its items are visible
   */
  isGroupVisible (layout: Section): boolean {
    return isGroupVisible(layout, this.questions, this.answers)
  }

  isQuestionVisible (item: SectionItem) {
    const question = this.questions.find(q => q.id === item)
    return question && question.condition ? engine.evaluate(question.condition, this.answers) : true
  }

  useLocationsGroup (section: Section): boolean {
    return section.items.some(
      (item) =>
        typeof item !== 'string' &&
            item.items.some((item) => typeof item === 'string' && /Location\d+Address/.test(item))
    )
  }

  filteredGroupItems (groupItems: SectionItem[]) {
    return groupItems.filter(item => this.isQuestionVisible(item))
  }

  markDown (input: string) {
    return renderMarkdown(input)
  }

  get isApplication (): boolean {
    const visibleOnPages = [
      'Application']
    return visibleOnPages.includes(this.$route?.meta?.title || '')
  }

  get isPreApp (): boolean {
    const visibleOnPages = [
      'Pre-Application']
    return visibleOnPages.includes(this.$route?.meta?.title || '')
  }

  groupSum (group: Section) {
    let total = 0
    group.items.forEach((item: SectionItem) => {
      const answer = this.answers[item as string]
      if (answer) {
        total += answer as number
      }
    })

    return group.meta?.features?.sum.replace('{{0}}', total.toLocaleString())
  }
}
